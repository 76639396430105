<template lang="pug">
div
	region-map.maplogo(:hour="parseInt(hour)" :type="type" :rundate="mapRundate" :weatherregion="region")
	weather-legend(type="legend-precip")
</template>

<script>
import gql from 'graphql-tag';
import RegionMap from '@dengl/site/components/weather/weathermap/regionmap.vue';
import WeatherLegend from '@dengl/site/components/weather/legends/index.vue';

export default {
	name: 'ForecastMapEmbed',
	components: {
		RegionMap,
		WeatherLegend
	},
	props: {
		rundate: {
			type: String,
			required: false,
			default: null
		},
		hour: {
			type: String,
			default: '144'
		},
		region: {
			type: String,
			default: 'alps',
			validator(val) {
				return ['alps', 'sauerland'].includes(val);
			}
		}
	},
	data() {
		return {
			type: 'snowforecast'
		};
	},
	computed: {
		mapRundate() {
			return (
				this.$dayjs(this.rundate).subtract(1, 'day').format('YYYY-MM-DD') ||
				this.$store.state.rundate
			);
		}
	},
	fragments: gql`
		fragment ForecastMapEmbedDataFragment on Embed {
			data {
				... on EmbedDataForecastMap {
					hour
					rundate
					region
				}
			}
		}
	`
};
</script>

<style lang="scss" scoped>
.maplogo {
	&:after {
		position: absolute;
		right: $spacer;
		top: $spacer;
		width: 140px;
		height: 30px;
		padding-top: 24px;
		background-size: fit;
		background-image: url('/images/logo.svg');
		background-repeat: no-repeat;
		content: '';

		@media #{map-get($display-breakpoints, 'xs-only')} {
			display: none;
		}
	}
}
</style>
